<template>
  <div id="app" class="tokyo_tm_all_wrap">
    <div class="leftpart">
      <div class="leftpart_inner">
        <div class="logo show-pointer">
          <img src="./assets/img/logo/dark.png" alt="" v-on:click="logoClick"
          />
          <div @click="toggleTheme()" class="colorChange">
            <i :class="{'fa-solid fa-moon fa-xl light-theme':color=='light','fas fa-sun fa-xl dark-theme':color=='dark'}"></i>
          </div>
        </div>
        <!-- End .logo -->
        <div class="menu">
          <router-link to="/">
              <img
                class="svg"
                src="./assets/img/svg/home-run.svg"
                alt="homerun"
              >
              <span class="menu_content">Home</span>
          </router-link>      
          <!-- End Home -->
          <router-link to="/about">
            <img class="svg" src="./assets/img/svg/avatar.svg" alt="avatar" />
            <span class="menu_content">About</span>
          </router-link>
          <!-- End About -->

          <router-link to="/portfolio">
            <img
              class="svg"
              src="./assets/img/svg/briefcase.svg"
              alt="briefcase"
            />
            <span class="menu_content">Portfolio</span>
          </router-link>

          <!-- End Portfolio -->
          <router-link to="/contact" >
            <img class="svg" src="./assets/img/svg/mail.svg" alt="mail" />
            <span class="menu_content">Contact</span>
          </router-link>
          <!-- End Contact -->
        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }} Created by
            <a
              href="https://github.com/pemba17"
              target="_blank"
              rel="noreferrer"
            >
              Pemba
            </a>
          </p>
        </div>
        <!-- End copyright -->
      </div>
    </div>

    <div class="rightpart">
      <div class="rightpart_in">
        <div :class="{'tokyo_tm_section':$route.name!='About'}">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        activetab: 1,
        color:'light',
        toggleTheme() {
          if(document.body.classList.contains("dark")){
            document.body.classList.remove("dark");
            this.color="light";
          }else{
            document.body.classList.add("dark");
            this.color="dark";
          }
        }
      };
    },
    methods:{
      logoClick(){
        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
      }
    }
  };
</script>

<style scoped>
  .light-theme{
    color:black
  }

  .dark-theme{
    color:white;
  }
  
  .logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .colorChange{
    cursor:pointer;
    margin-left:10px;
  }

  .show-pointer{
    cursor: pointer;
  }
</style>
