import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'Pemba'
    },
    component: () =>
      import("../views/Home.vue"),
  },
  {
    path: '/about',
    name: "About",
    component: ()=> import("../views/About.vue")
  },
  {
    path: '/portfolio',
    name: "Portfolio",
    component: ()=> import("../views/Portfolio.vue")
  },
  {
    path: '/contact',
    name: "Contact",
    component: ()=> import("../views/Contact.vue")
  },
  {
    path:'/:catchAll(.*)',
    name:'404',
    component: ()=> import("../views/404.vue")
  }
];
const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});


export default router;