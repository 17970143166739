import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    baseURL: 'https://admin.pembas.com.np/'
  },
  mutations: {
  },
});

export default store;